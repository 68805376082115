<template>
  <div>
    <h1 class="text-h4">{{ $lang.routes.users }}</h1>
    <v-divider class="my-2"></v-divider>
    <v-row v-if="err || success" dense no-gutters class="px-3">
      <v-col v-if="err" cols="12">
        <v-alert color="error" :data-cy="err">{{ err }}</v-alert>
      </v-col>
      <v-col v-if="success" cols="12">
        <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
      </v-col>
    </v-row>
    <v-row dense no-gutters>
      <v-col cols="12">
        <Table
          :key="tableKey"
          :items="users"
          :loading="loading"
          @activeOnly="activeOnlyFunction($event)"
          @changeStatus="changeStatus($event)"
          @fetchUsers="fetchUsers($event)"
        ></Table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Table from './UsersDatatable'
import {
  getUsersUsingGET as getUsers,
  disableUserUsingGET as disableUser,
  enableUserUsingGET as enableUser
} from '@/utils/api'

export default {
  components: {
    Table
  },
  data() {
    return {
      err: '',
      success: '',
      activeOnly: true,
      tableKey: 0,
      loading: false,
      users: { items: [], meta: {} }
    }
  },
  computed: {},
  created() {
    if (this.$route.params && this.$route.params.message) {
      this.success = this.$route.params.message
      setTimeout(() => this.success = '', 5000)
    }
  },
  methods: {
    activeOnlyFunction(event) {
      this.activeOnly = event.activeOnly

      this.fetchUsers(event.options)
    },
    fetchUsers(options) {
      this.loading = true

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      if (options && options.search && options.search.length > 1) {
        obj.name = options.search
      }

      if (this.activeOnly) obj.status = 'ACTIVE'

      getUsers(obj)
        .then((res) => {
          this.users = res.data.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    },
    async changeStatus (item) {
      try {
        const res = item.status === 'ACTIVE' ? await disableUser({ id: item.id }) : await enableUser({ id: item.id })

        if (res.status !== 200) {
          this.err = this.$lang.errors.userStatusUpdate
          setTimeout(() => this.err = '', 5000)

          return
        }
        this.success = item.status !== 'ACTIVE' ? this.$lang.success.userActivated : this.$lang.success.userDeactivated

        const changedItem = this.users.items.find((x) => x.id === item.id)

        if (changedItem) this.users.items.find((x) => x.id === item.id).status = res.data.data.status

        setTimeout(() => this.success = '', 5000)

      } catch (err) {
        this.err = err

        setTimeout(() => this.err = '', 5000)
      }
    }
  }
}
</script>
